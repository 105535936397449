import React, { useEffect, useState, useRef } from "react";
import DataService from "../../../services/data.service";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { format } from "date-fns";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../../common/Header";
import Footer from "../../../common/Footer";
import Sidebar from "../../../common/sidebar";
import { Link } from "react-router-dom";
import "../../../CustomCss/SingleProduct.css"

const ViewCompany = () => {
  const form = React.useRef();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [disabled, setDisabled] = useState(true);
  const params = useParams();
  //console.log(props)


  const [company, setCompany] = useState([])

  const getCompanyProfileData= async () => {
      await DataService.getCompanyProfile(params.slug).then((data) => {
          setCompany(data.data.data);
      });
  };
  useEffect(() => {
    getCompanyProfileData();
  }, []);



  const getData = async () => {
    await DataService.getCustomerDetail(company.id).then((data) => {
      setData(data?.data?.data);
    });
  };
  const onChangeStatus = (e) => {
    const data = e.target.value;
    setStatus(data);
    setDisabled(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData();
    if (status) {
      data.append("status", status);
    }
    DataService.updateCustomerDetail(company?.id, data).then(
      () => {
        setLoading(false);
        toast.success("Vendor updated successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };
  return (
    <>
        <div className="bg-grey h-100">
      <Header />
      
<section className="content-area mt-4">
    <Sidebar/>
    <div className="Right-content">
      <div className="main-content">
      <div className="row">
        <ToastContainer></ToastContainer>
        <div className="col-md-12">
          <h3>Vendor Details</h3>
          <div className="row">
            <div className="col-md-8">
                          <div className="company-profile-tab">
                <div className="company-head mb-4">
                  <h2>{company?.name}</h2>
                  <h3>Company Album</h3>
                </div>
                <div className="company-images">
               
                </div>
                <div className="profile-table">
                  <table className="table mt-2">
                    <tbody>
                      <tr>
                        <td className="table-title">Business type</td>
                        <td>
                          {/* {company?.business_type
                            ? JSON.parse(company?.business_type).join(
                                ","
                              )
                            : ""} */}
                        </td>
                        <td className="table-title">Country / Region</td>
                        <td>Guangdong, China</td>
                      </tr>
                      <tr>
                        <td className="table-title">Main Products</td>
                        <td>
                          <Link to="/">{company?.main_products}</Link>
                        </td>
                        <td className="table-title">Export percentage</td>

                        <td> {company?.export_percentage}</td>
                      </tr>
                      <tr>
                        <td className="table-title">Operation Country</td>
                        <td>{company?.operation_country}</td>

                        <td className="table-title">Register Country</td>
                        <td>{company?.register_country}</td>
                      </tr>
                      <tr>
                        <td className="table-title">Nearest Port</td>
                        <td>
                          <Link to="">{company?.nearest_port}</Link>
                        </td>
                        <td className="table-title">Register Year</td>
                        <td> {company?.register_year}</td>
                      </tr>
                      <tr>
                        <td className="table-title">Started Exporting</td>
                        <td>{company?.started_exporting}</td>
                        <td className="table-title">Register State</td>
                        <td>{company?.register_state}</td>
                      </tr>
                      <tr>
                        <td className="table-title">Description</td>
                        <td>
                          <Link to="">{company?.description}</Link>
                        </td>
                        <td className="table-title">Company Advantages</td>
                        <td>
                          {company?.company_advantages
                            ? JSON.parse(company?.company_advantages)
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td className="table-title">Office Size</td>
                        <td>
                          <Link to="">{company?.office_size}</Link>
                        </td>
                        <td className="table-title">Operation City</td>
                        <td>{company?.operation_city}</td>
                      </tr>
                      <tr>
                        <td className="table-title">Opertaion Steet</td>
                        <td>
                          <Link to="">
                            {company?.opertaion_steet}
                          </Link>
                        </td>
                        <td className="table-title">Rd Staff</td>
                        <td>{company?.rd_staff}</td>
                      </tr>
                      <tr>
                        <td className="table-title">Qc Staff</td>
                        <td>
                          <Link to="">{company?.qc_staff}</Link>
                        </td>
                        <td className="table-title">Language Spoken</td>

                        <td>
                          {" "}
                          {company?.language_spoken
                            ? JSON.parse(
                                company?.language_spoken
                              ).join(",")
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td className="table-title">Manufacture Contract</td>
                        <td>
                          <Link to="">
                            {company?.manufacture_contract
                              ? JSON.parse(
                                  company?.manufacture_contract
                                )
                              : ""}
                          </Link>
                        </td>
                        <td className="table-title">Operation Zip</td>
                        <td>{company?.operation_zip}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="capacity-part mt-4">
                  <div className="capacity-head">
                    <h2>PRODUCT CAPACITY</h2>
                  </div>
                  <div className="capacity-table-head pt-4">
                    <h2>Factory information</h2>
                  </div>
                  <div className="capacity-table">
                    <table className="table mb-0">
                      <tbody>
                        <tr>
                          <td className="table-title">Factory Size</td>
                          <td>{company?.factory_size}</td>
                        </tr>
                        <tr>
                          <td className="table-title">
                            Factory Country/Region
                          </td>
                          <td>{company?.factory_location}</td>
                        </tr>
                        <tr>
                          <td className="table-title">Factory Size</td>
                          <td>1{company?.main_products}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-xl-3 col-md-5 col-12">
                  <form onSubmit={handleSubmit} ref={form}>
                    <div class="mb-3">
                      <label class="form-label">Change Status</label>
                      <select
                        key={company?.status}
                        defaultValue={company?.status}
                        className="form-select"
                        onChange={onChangeStatus}
                      >
                        {company?.status === "active" ? (
                          <>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </>
                        ) : (
                          <>
                            <option value="pending">Pending</option>
                            <option value="active">Approved</option>
                            <option value="reject">Rejected</option>
                          </>
                        )}
                      </select>
                    </div>
                    <div class="mb-3">
                      <button
                        disabled={disabled || loading}
                        className="btn btn-primary"
                      >
                        {loading && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span>Update</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              {data.file_path ? (
                <img
                  src={"https://api.dealitt.com/" + data.file_path}
                  className="w-100"
                  alt={data.first_name}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      </div> 
      <Footer />
    </div>
</section>
      
     
    </div>
    </>
  );
};

export default ViewCompany;
