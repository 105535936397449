import React from "react";
import Footer from "../common/Footer";
import Header from "../common/Header";
import Sidebar from "../common/sidebar";
import StaticPageContent from "../section/home/static-pages/common-content";
const StaticPage = () => {
   
    return (
        <div className="bg-grey h-100">
            <Header />
            <section className="content-area mt-4">
                <Sidebar />
                <div className="Right-content">
                    <div className="main-content">
                        <StaticPageContent/>
                    </div>
                    <Footer />
                </div>
            </section>
        </div>
    );
};

export default StaticPage;