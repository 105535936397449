import React, {useEffect, useState, useRef } from "react";
import { Link } from 'react-router-dom';
import AuthService from "../services/auth.service";
const Sidebar = () => {
  const [user, setUser] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const url = window.location.href
  useEffect(() => {
    const userData = AuthService.getCurrentUser();
    setUser(userData);
    
  }, []);
  const toggle = (event) => {
    if(event.target.classList.contains('open')){
      event.target.classList.remove('open');
      event.target.classList.add('close');
    }else{
      event.target.classList.add('open');
      event.target.classList.remove('close');
    }
    
    setIsOpen(!isOpen);
  }
  return (

    <div className='sidebar'>
      <Link to={"/my-profile"} className="profile d-flex align-items-center">
        <figure className='mb-0'>
        {(user?.file_path ?
                        <img  src={"https://api.dealitt.com/" + user?.file_path} alt="profie image" />
                        : <img src="../assets/img/pro-img.jpg" alt="profie image" />
                    )}
          <img src='../assets/img/pro-img.jpg' alt='profie image' />
          </figure>
        <div className="profile-detail ps-2">
          <h6 className='mb-1 text-dark-grey'>Hello</h6>
          <p className='text-dark-grey mb-0'>{user.first_name}</p>
        </div>
      </Link>

      <nav className='mt-4'>
        <ul>
          <li className={url.includes('dashboard')?'active':''}>
            <Link to={"/dashboard"} >
              <i><svg width="24"  viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.5 2.75C0.5 1.50736 1.50736 0.5 2.75 0.5L7.25 0.5C8.49264 0.5 9.5 1.50736 9.5 2.75V7.25C9.5 8.49264 8.49264 9.5 7.25 9.5H2.75C1.50736 9.5 0.5 8.49264 0.5 7.25L0.5 2.75ZM12.5 2.75C12.5 1.50736 13.5074 0.5 14.75 0.5L19.25 0.5C20.4926 0.5 21.5 1.50736 21.5 2.75V7.25C21.5 8.49264 20.4926 9.5 19.25 9.5H14.75C13.5074 9.5 12.5 8.49264 12.5 7.25V2.75ZM0.5 14.75C0.5 13.5074 1.50736 12.5 2.75 12.5H7.25C8.49264 12.5 9.5 13.5074 9.5 14.75V19.25C9.5 20.4926 8.49264 21.5 7.25 21.5H2.75C1.50736 21.5 0.5 20.4926 0.5 19.25L0.5 14.75ZM12.5 14.75C12.5 13.5074 13.5074 12.5 14.75 12.5H19.25C20.4926 12.5 21.5 13.5074 21.5 14.75V19.25C21.5 20.4926 20.4926 21.5 19.25 21.5H14.75C13.5074 21.5 12.5 20.4926 12.5 19.25V14.75Z" fill="#F6F5FA" stroke="#161616" />
              </svg>
              </i><span>Dashboard</span>
            </Link>
          </li>
          <li className={url.includes('vendor-stores')?'active':''}>
            <Link to={"/vendor-stores"}>
              <i><svg width="24" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.02897 7.08223L11.3052 0.448201C11.4523 0.150244 11.7248 0.000844778 11.9977 3.56861e-06C12.2721 -0.000841949 12.5469 0.14856 12.6948 0.448204L15.971 7.08223L23.3187 8.14924C23.7194 8.20743 23.9712 8.55309 23.9977 8.9235C24.0008 8.96646 24.0008 9.00975 23.9977 9.05297C23.9833 9.25226 23.902 9.44992 23.7421 9.60575L18.4586 14.7526L19.7031 22.0045C19.8198 22.6845 19.1635 23.2167 18.5838 22.9122L12 19.453L5.41616 22.9122C5.3437 22.9502 5.27005 22.9752 5.19685 22.9885C4.68449 23.0813 4.19474 22.5995 4.29685 22.0045L5.54144 14.7526L0.257945 9.60575C0.080497 9.43289 -0.000170449 9.20857 2.70384e-07 8.98765C0.000126456 8.82435 0.044417 8.66291 0.126434 8.52547C0.242222 8.33144 0.433201 8.18526 0.681258 8.14924L8.02897 7.08223ZM11.9977 17.674C12.117 17.6736 12.2363 17.7023 12.3462 17.76L17.8749 20.6647L16.8335 14.5969C16.7844 14.3108 16.8767 14.0179 17.0776 13.8222L21.4379 9.57475L15.3594 8.69206C15.1081 8.65557 14.8887 8.49152 14.7702 8.2515L12 2.64205L11.9977 2.64675V17.674Z" fill="black" />
              </svg></i><span>Companies profile</span>
            </Link>
            
          </li>
          {/* <li>  
                <div class="Shop-decoration">
                <Link to={""} >
              <i><svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 2.125C0 1.08947 0.839466 0.25 1.875 0.25H18.125C19.1605 0.25 20 1.08947 20 2.125V4C20 4.34518 19.7202 4.625 19.375 4.625C18.3395 4.625 17.5 5.46447 17.5 6.5C17.5 7.53553 18.3395 8.375 19.375 8.375C19.7202 8.375 20 8.65482 20 9V10.875C20 11.9105 19.1605 12.75 18.125 12.75H1.875C0.839466 12.75 0 11.9105 0 10.875V9C0 8.65482 0.279822 8.375 0.625 8.375C1.66053 8.375 2.5 7.53553 2.5 6.5C2.5 5.46447 1.66053 4.625 0.625 4.625C0.279822 4.625 0 4.34518 0 4V2.125ZM1.875 1.5C1.52982 1.5 1.25 1.77982 1.25 2.125V3.43751C2.6764 3.72706 3.75 4.98815 3.75 6.5C3.75 8.01185 2.6764 9.27294 1.25 9.56249V10.875C1.25 11.2202 1.52982 11.5 1.875 11.5H18.125C18.4702 11.5 18.75 11.2202 18.75 10.875V9.56249C17.3236 9.27294 16.25 8.01185 16.25 6.5C16.25 4.98815 17.3236 3.72706 18.75 3.43751V2.125C18.75 1.77982 18.4702 1.5 18.125 1.5H1.875Z" fill="black" />
              </svg>
              </i><span>Shop Decoration</span>
            </Link>
                <a class="Shop_dropbtn">Shop Decoration</a>
                <div class="dropdown-content">
                <li>
            <Link to={"/edit-company-profile"} >
              <i><svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 2.125C0 1.08947 0.839466 0.25 1.875 0.25H18.125C19.1605 0.25 20 1.08947 20 2.125V4C20 4.34518 19.7202 4.625 19.375 4.625C18.3395 4.625 17.5 5.46447 17.5 6.5C17.5 7.53553 18.3395 8.375 19.375 8.375C19.7202 8.375 20 8.65482 20 9V10.875C20 11.9105 19.1605 12.75 18.125 12.75H1.875C0.839466 12.75 0 11.9105 0 10.875V9C0 8.65482 0.279822 8.375 0.625 8.375C1.66053 8.375 2.5 7.53553 2.5 6.5C2.5 5.46447 1.66053 4.625 0.625 4.625C0.279822 4.625 0 4.34518 0 4V2.125ZM1.875 1.5C1.52982 1.5 1.25 1.77982 1.25 2.125V3.43751C2.6764 3.72706 3.75 4.98815 3.75 6.5C3.75 8.01185 2.6764 9.27294 1.25 9.56249V10.875C1.25 11.2202 1.52982 11.5 1.875 11.5H18.125C18.4702 11.5 18.75 11.2202 18.75 10.875V9.56249C17.3236 9.27294 16.25 8.01185 16.25 6.5C16.25 4.98815 17.3236 3.72706 18.75 3.43751V2.125C18.75 1.77982 18.4702 1.5 18.125 1.5H1.875Z" fill="black" />
              </svg>
              </i><span>Edit Company Profile</span>
            </Link>
          </li>
                </div>
              </div>
              </li> */}

          <li className={url.includes('product')?'active':''}>
            <Link className={url.includes('product')?'open':'close'} onClick={(e)=>toggle(e)} >
              <i><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.2785 1.66918C12.0997 1.59766 11.9003 1.59766 11.7215 1.66918L2.76944 5.24999L12 8.94222L21.2306 5.24999L12.2785 1.66918ZM22.5 6.35777L12.75 10.2578V22.1422L22.5 18.2422V6.35777ZM11.25 22.1422V10.2578L1.5 6.35777V18.2422L11.25 22.1422ZM11.1644 0.276467C11.7008 0.0618971 12.2992 0.061897 12.8356 0.276467L23.5285 4.55363C23.8133 4.66753 24 4.94331 24 5.24999V18.2422C24 18.8556 23.6266 19.4071 23.0571 19.6349L12.2785 23.9463C12.0997 24.0179 11.9003 24.0179 11.7215 23.9463L0.942914 19.6349C0.373427 19.4071 0 18.8556 0 18.2422V5.24999C0 4.94331 0.186713 4.66753 0.471457 4.55363L11.1644 0.276467Z" fill="#161616" />
              </svg>
              </i><span>Products</span>
              <i className='ms-auto'><svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.146447 0.646447C0.341709 0.451184 0.658291 0.451184 0.853553 0.646447L6.85355 6.64645C7.04882 6.84171 7.04882 7.15829 6.85355 7.35355L0.853553 13.3536C0.658291 13.5488 0.341709 13.5488 0.146447 13.3536C-0.0488155 13.1583 -0.0488155 12.8417 0.146447 12.6464L5.79289 7L0.146447 1.35355C-0.0488155 1.15829 -0.0488155 0.841709 0.146447 0.646447Z" fill="#161616" />
              </svg>
              </i>
            </Link>
            <ul className='ps-4'>
              <li>
                <Link to={"/products"} >All Products</Link>
              </li>
              <li>
                <Link to={"/add-product"} >Add Products</Link>
              </li>
            </ul>
          </li>

          <li className={url.includes('category') || url.includes('categories')?'active':''}>
            <Link className={url.includes('category') || url.includes('categories')?'open':'close'} onClick={(e)=>toggle(e)} >
              <i><svg width="24" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.5 2.75C0.5 1.50736 1.50736 0.5 2.75 0.5L7.25 0.5C8.49264 0.5 9.5 1.50736 9.5 2.75V7.25C9.5 8.49264 8.49264 9.5 7.25 9.5H2.75C1.50736 9.5 0.5 8.49264 0.5 7.25L0.5 2.75ZM12.5 2.75C12.5 1.50736 13.5074 0.5 14.75 0.5L19.25 0.5C20.4926 0.5 21.5 1.50736 21.5 2.75V7.25C21.5 8.49264 20.4926 9.5 19.25 9.5H14.75C13.5074 9.5 12.5 8.49264 12.5 7.25V2.75ZM0.5 14.75C0.5 13.5074 1.50736 12.5 2.75 12.5H7.25C8.49264 12.5 9.5 13.5074 9.5 14.75V19.25C9.5 20.4926 8.49264 21.5 7.25 21.5H2.75C1.50736 21.5 0.5 20.4926 0.5 19.25L0.5 14.75ZM12.5 14.75C12.5 13.5074 13.5074 12.5 14.75 12.5H19.25C20.4926 12.5 21.5 13.5074 21.5 14.75V19.25C21.5 20.4926 20.4926 21.5 19.25 21.5H14.75C13.5074 21.5 12.5 20.4926 12.5 19.25V14.75Z" fill="#F6F5FA" stroke="#161616" />
              </svg>
              </i><span>Category</span>
              <i className='ms-auto'><svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.146447 0.646447C0.341709 0.451184 0.658291 0.451184 0.853553 0.646447L6.85355 6.64645C7.04882 6.84171 7.04882 7.15829 6.85355 7.35355L0.853553 13.3536C0.658291 13.5488 0.341709 13.5488 0.146447 13.3536C-0.0488155 13.1583 -0.0488155 12.8417 0.146447 12.6464L5.79289 7L0.146447 1.35355C-0.0488155 1.15829 -0.0488155 0.841709 0.146447 0.646447Z" fill="#02224E" />
              </svg>
              </i>
            </Link>

            <ul className='ps-4'>
              <li>
                <Link to={"/master-categories"} >Master Category</Link>
              </li>
              <li>
                <Link to={"/categories"} >Category</Link>
              </li>

              <li>
                <Link to={"/sub-categories"} >Sub Category</Link>
              </li>
            </ul>
          </li>

          <li className={url.includes('blog')?'active':''}>
            <Link className={url.includes('blog')?'open':'close'} onClick={(e)=>toggle(e)}>
              <i><svg width="24" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.75 7.5C7.16421 7.5 7.5 7.16421 7.5 6.75C7.5 6.33579 7.16421 6 6.75 6C6.33579 6 6 6.33579 6 6.75C6 7.16421 6.33579 7.5 6.75 7.5Z" fill="black" />
                <path d="M9 7.5C9.41421 7.5 9.75 7.16421 9.75 6.75C9.75 6.33579 9.41421 6 9 6C8.58579 6 8.25 6.33579 8.25 6.75C8.25 7.16421 8.58579 7.5 9 7.5Z" fill="black" />
                <path d="M12 6.75C12 7.16421 11.6642 7.5 11.25 7.5C10.8358 7.5 10.5 7.16421 10.5 6.75C10.5 6.33579 10.8358 6 11.25 6C11.6642 6 12 6.33579 12 6.75Z" fill="black" />
                <path d="M18 0C19.6569 0 21 1.34315 21 3C22.6569 3 24 4.34315 24 6V18C24 19.6569 22.6569 21 21 21H6C4.34315 21 3 19.6569 3 18C1.34315 18 0 16.6569 0 15V3C0 1.34315 1.34315 0 3 0H18ZM3 16.5V6C3 4.34315 4.34315 3 6 3L19.5 3C19.5 2.17157 18.8284 1.5 18 1.5H3C2.17157 1.5 1.5 2.17157 1.5 3V15C1.5 15.8284 2.17157 16.5 3 16.5ZM4.5 10.5V18C4.5 18.8284 5.17157 19.5 6 19.5H21C21.8284 19.5 22.5 18.8284 22.5 18V10.5H4.5ZM22.5 9V6C22.5 5.17157 21.8284 4.5 21 4.5H6C5.17157 4.5 4.5 5.17157 4.5 6V9H22.5Z" fill="black" />
              </svg>
              </i><span>Blogs</span>
              <i className='ms-auto'><svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.146447 0.646447C0.341709 0.451184 0.658291 0.451184 0.853553 0.646447L6.85355 6.64645C7.04882 6.84171 7.04882 7.15829 6.85355 7.35355L0.853553 13.3536C0.658291 13.5488 0.341709 13.5488 0.146447 13.3536C-0.0488155 13.1583 -0.0488155 12.8417 0.146447 12.6464L5.79289 7L0.146447 1.35355C-0.0488155 1.15829 -0.0488155 0.841709 0.146447 0.646447Z" fill="#02224E" />
              </svg>
              </i>
            </Link>
            <ul className='ps-4'>
              <li><Link to={"/blogs"} >All blogs</Link></li>
              <li><Link to={"/add-blog"} >Add Blog</Link></li>
              
            </ul>
          </li>

          <li className={url.includes('static-page')?'active':''}>
            <Link className={url.includes('static-page')?'open':'close'} onClick={(e)=>toggle(e)}>
              <i><svg width="24" viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.4615 0H7.07692C5.12268 0 3.53846 1.62297 3.53846 3.625C1.58422 3.625 0 5.24797 0 7.25V25.375C0 27.377 1.58422 29 3.53846 29H15.9231C17.8773 29 19.4615 27.377 19.4615 25.375C21.4158 25.375 23 23.752 23 21.75V3.625C23 1.62297 21.4158 0 19.4615 0ZM19.4615 23.5625V7.25C19.4615 5.24797 17.8773 3.625 15.9231 3.625H5.30769C5.30769 2.62398 6.0998 1.8125 7.07692 1.8125H19.4615C20.4387 1.8125 21.2308 2.62398 21.2308 3.625V21.75C21.2308 22.751 20.4387 23.5625 19.4615 23.5625ZM1.76923 7.25C1.76923 6.24898 2.56134 5.4375 3.53846 5.4375H15.9231C16.9002 5.4375 17.6923 6.24898 17.6923 7.25V25.375C17.6923 26.376 16.9002 27.1875 15.9231 27.1875H3.53846C2.56134 27.1875 1.76923 26.376 1.76923 25.375V7.25Z" fill="black" />
              </svg>
              </i><span>Pages</span>
              <i className='ms-auto'><svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.146447 0.646447C0.341709 0.451184 0.658291 0.451184 0.853553 0.646447L6.85355 6.64645C7.04882 6.84171 7.04882 7.15829 6.85355 7.35355L0.853553 13.3536C0.658291 13.5488 0.341709 13.5488 0.146447 13.3536C-0.0488155 13.1583 -0.0488155 12.8417 0.146447 12.6464L5.79289 7L0.146447 1.35355C-0.0488155 1.15829 -0.0488155 0.841709 0.146447 0.646447Z" fill="#02224E" />
              </svg>
              </i>
            </Link>
            <ul className='ps-4'>
              <li><Link to={"/static-page/about-us"} >About us</Link></li>
              <li><Link to={"/static-page/refund-policy"} >Returns and Refunds Policy</Link></li>
              <li><Link to={"/static-page/terms-conditions"} >Terms and Conditions</Link></li>
              <li><Link to={"/static-page/privacy-notice"} >Privacy Notice</Link></li>
              <li><Link to={"/static-page/contact-us"} >Contact Us</Link></li>
              <li><Link to={"/static-page/cookies"} >Cookies Notice</Link></li>
              <li><Link to={"/static-page/become-vendor"} >Become a vendor</Link></li>
            </ul>
          </li>

          <li className={url.includes('customer')?'active':''}>
            <Link className={url.includes('customer')?'open':'close'} onClick={(e)=>toggle(e)}>
              <i><svg width="24" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M28.125 22C28.125 22 30 22 30 20.1667C30 18.3333 28.125 12.8333 20.625 12.8333C13.125 12.8333 11.25 18.3333 11.25 20.1667C11.25 22 13.125 22 13.125 22H28.125ZM13.1669 20.1667C13.1604 20.1659 13.1513 20.1648 13.1403 20.163C13.1351 20.1621 13.13 20.1612 13.125 20.1603C13.1277 19.6762 13.4379 18.2727 14.5486 17.0056C15.5875 15.8205 17.405 14.6667 20.625 14.6667C23.845 14.6667 25.6625 15.8205 26.7014 17.0056C27.8121 18.2727 28.1223 19.6762 28.125 20.1603C28.12 20.1612 28.1149 20.1621 28.1097 20.163C28.0987 20.1648 28.0896 20.1659 28.0831 20.1667H13.1669Z" fill="black" />
                <path d="M20.625 9.16667C22.6961 9.16667 24.375 7.52504 24.375 5.5C24.375 3.47496 22.6961 1.83333 20.625 1.83333C18.5539 1.83333 16.875 3.47496 16.875 5.5C16.875 7.52504 18.5539 9.16667 20.625 9.16667ZM26.25 5.5C26.25 8.53757 23.7316 11 20.625 11C17.5184 11 15 8.53757 15 5.5C15 2.46243 17.5184 0 20.625 0C23.7316 0 26.25 2.46243 26.25 5.5Z" fill="black" />
                <path d="M13.0049 13.3466C12.3152 13.1309 11.5491 12.9741 10.6993 12.8939C10.2787 12.8543 9.83753 12.8333 9.375 12.8333C1.875 12.8333 0 18.3333 0 20.1667C0 21.3889 0.625 22 1.875 22H9.78068C9.51668 21.4792 9.375 20.8618 9.375 20.1667C9.375 18.3145 10.0823 16.423 11.4184 14.843C11.8749 14.3032 12.4047 13.7997 13.0049 13.3466ZM9.22508 14.6675C8.10479 16.3416 7.5 18.2503 7.5 20.1667H1.875C1.875 19.6887 2.18296 18.2783 3.29861 17.0056C4.32128 15.839 6.09842 14.7027 9.22508 14.6675Z" fill="black" />
                <path d="M2.8125 6.41667C2.8125 3.3791 5.3309 0.916667 8.4375 0.916667C11.5441 0.916667 14.0625 3.3791 14.0625 6.41667C14.0625 9.45423 11.5441 11.9167 8.4375 11.9167C5.3309 11.9167 2.8125 9.45423 2.8125 6.41667ZM8.4375 2.75C6.36643 2.75 4.6875 4.39162 4.6875 6.41667C4.6875 8.44171 6.36643 10.0833 8.4375 10.0833C10.5086 10.0833 12.1875 8.44171 12.1875 6.41667C12.1875 4.39162 10.5086 2.75 8.4375 2.75Z" fill="black" />
              </svg>
              </i><span>Customer</span>
              <i className='ms-auto'><svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.146447 0.646447C0.341709 0.451184 0.658291 0.451184 0.853553 0.646447L6.85355 6.64645C7.04882 6.84171 7.04882 7.15829 6.85355 7.35355L0.853553 13.3536C0.658291 13.5488 0.341709 13.5488 0.146447 13.3536C-0.0488155 13.1583 -0.0488155 12.8417 0.146447 12.6464L5.79289 7L0.146447 1.35355C-0.0488155 1.15829 -0.0488155 0.841709 0.146447 0.646447Z" fill="#02224E" />
              </svg>
              </i>
            </Link>
            <ul className='ps-4'>
              <li>
                <Link to={"/customers"} >All Customer</Link>
                <Link to={"/add-customer"} >Add Customer</Link>
              </li>

            </ul>
          </li>

          <li className={url.includes('vendor')?'active':''}>
            <Link className={url.includes('vendor')?'open':'close'} onClick={(e)=>toggle(e)}>
              <i><svg width="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 9C11.4853 9 13.5 6.98528 13.5 4.5C13.5 2.01472 11.4853 0 9 0C6.51472 0 4.5 2.01472 4.5 4.5C4.5 6.98528 6.51472 9 9 9ZM12 4.5C12 6.15685 10.6569 7.5 9 7.5C7.34315 7.5 6 6.15685 6 4.5C6 2.84315 7.34315 1.5 9 1.5C10.6569 1.5 12 2.84315 12 4.5Z" fill="black" />
                <path d="M18 16.5C18 18 16.5 18 16.5 18H1.5C1.5 18 0 18 0 16.5C0 15 1.5 10.5 9 10.5C16.5 10.5 18 15 18 16.5ZM16.5 16.4948C16.4978 16.1246 16.2693 15.0157 15.2518 13.9982C14.2734 13.0198 12.4336 12 8.99998 12C5.56632 12 3.72653 13.0198 2.74815 13.9982C1.73063 15.0157 1.50214 16.1246 1.5 16.4948H16.5Z" fill="black" />
              </svg>
              </i><span>Vendors</span>
              <i className='ms-auto'><svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.146447 0.646447C0.341709 0.451184 0.658291 0.451184 0.853553 0.646447L6.85355 6.64645C7.04882 6.84171 7.04882 7.15829 6.85355 7.35355L0.853553 13.3536C0.658291 13.5488 0.341709 13.5488 0.146447 13.3536C-0.0488155 13.1583 -0.0488155 12.8417 0.146447 12.6464L5.79289 7L0.146447 1.35355C-0.0488155 1.15829 -0.0488155 0.841709 0.146447 0.646447Z" fill="#02224E" />
              </svg>
              </i>
            </Link>
            <ul className='ps-4'>
              <li>
              <Link to={"/vendor"} >All Vendor</Link>
              <Link to={"/add-vendor"} >Add Vendor</Link>
              </li>

            </ul>
          </li>

          <li className={url.includes('orders')?'active':''}>
            <Link to={"/orders"}>
              <i><svg width="24" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.5 2L4.5 8.87868L15 19.3787L21.8787 12.5L11.3787 2L4.5 2ZM3 2C3 1.17157 3.67157 0.5 4.5 0.5H11.3787C11.7765 0.5 12.158 0.658035 12.4393 0.93934L22.9393 11.4393C23.5251 12.0251 23.5251 12.9749 22.9393 13.5607L16.0607 20.4393C15.4749 21.0251 14.5251 21.0251 13.9393 20.4393L3.43934 9.93934C3.15803 9.65803 3 9.2765 3 8.87868V2Z" fill="#161616" />
                <path d="M8.25 6.5C7.83579 6.5 7.5 6.16421 7.5 5.75C7.5 5.33579 7.83579 5 8.25 5C8.66421 5 9 5.33579 9 5.75C9 6.16421 8.66421 6.5 8.25 6.5ZM8.25 8C9.49264 8 10.5 6.99264 10.5 5.75C10.5 4.50736 9.49264 3.5 8.25 3.5C7.00736 3.5 6 4.50736 6 5.75C6 6.99264 7.00736 8 8.25 8Z" fill="#161616" />
                <path d="M1.5 9.62868C1.5 10.0265 1.65803 10.408 1.93934 10.6893L13.125 21.875L13.0607 21.9393C12.4749 22.5251 11.5251 22.5251 10.9393 21.9393L0.439339 11.4393C0.158034 11.158 0 10.7765 0 10.3787V3.5C0 2.67157 0.671573 2 1.5 2V9.62868Z" fill="#161616" />
              </svg></i><span>Order</span>
            </Link>
          </li>
          <li className={url.includes('orders')?'active':''}>
            <Link to={"/refund-request"}>
              <i><svg width="24" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.5 2L4.5 8.87868L15 19.3787L21.8787 12.5L11.3787 2L4.5 2ZM3 2C3 1.17157 3.67157 0.5 4.5 0.5H11.3787C11.7765 0.5 12.158 0.658035 12.4393 0.93934L22.9393 11.4393C23.5251 12.0251 23.5251 12.9749 22.9393 13.5607L16.0607 20.4393C15.4749 21.0251 14.5251 21.0251 13.9393 20.4393L3.43934 9.93934C3.15803 9.65803 3 9.2765 3 8.87868V2Z" fill="#161616" />
                <path d="M8.25 6.5C7.83579 6.5 7.5 6.16421 7.5 5.75C7.5 5.33579 7.83579 5 8.25 5C8.66421 5 9 5.33579 9 5.75C9 6.16421 8.66421 6.5 8.25 6.5ZM8.25 8C9.49264 8 10.5 6.99264 10.5 5.75C10.5 4.50736 9.49264 3.5 8.25 3.5C7.00736 3.5 6 4.50736 6 5.75C6 6.99264 7.00736 8 8.25 8Z" fill="#161616" />
                <path d="M1.5 9.62868C1.5 10.0265 1.65803 10.408 1.93934 10.6893L13.125 21.875L13.0607 21.9393C12.4749 22.5251 11.5251 22.5251 10.9393 21.9393L0.439339 11.4393C0.158034 11.158 0 10.7765 0 10.3787V3.5C0 2.67157 0.671573 2 1.5 2V9.62868Z" fill="#161616" />
              </svg></i><span>Refund Requests</span>
            </Link>
          </li>

          <li className={url.includes('invoice')?'active':''}>
            <Link to={"/invoice"}>
              <i><svg width="24" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 3C0 1.34315 1.34315 0 3 0H21C22.6569 0 24 1.34315 24 3V15C24 16.6569 22.6569 18 21 18H3C1.34315 18 0 16.6569 0 15V3ZM3 1.5C2.17157 1.5 1.5 2.17157 1.5 3V4.5H22.5V3C22.5 2.17157 21.8284 1.5 21 1.5H3ZM22.5 7.5H1.5V15C1.5 15.8284 2.17157 16.5 3 16.5H21C21.8284 16.5 22.5 15.8284 22.5 15V7.5Z" fill="#161616" />
                <path d="M3 12C3 11.1716 3.67157 10.5 4.5 10.5H6C6.82843 10.5 7.5 11.1716 7.5 12V13.5C7.5 14.3284 6.82843 15 6 15H4.5C3.67157 15 3 14.3284 3 13.5V12Z" fill="#161616" />
              </svg> </i><span>Invoice Manager</span>
            </Link>
          </li>

          <li className={url.includes('review')?'active':''}>
            <Link to={"/review"}>
              <i><svg width="24" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.02897 7.08223L11.3052 0.448201C11.4523 0.150244 11.7248 0.000844778 11.9977 3.56861e-06C12.2721 -0.000841949 12.5469 0.14856 12.6948 0.448204L15.971 7.08223L23.3187 8.14924C23.7194 8.20743 23.9712 8.55309 23.9977 8.9235C24.0008 8.96646 24.0008 9.00975 23.9977 9.05297C23.9833 9.25226 23.902 9.44992 23.7421 9.60575L18.4586 14.7526L19.7031 22.0045C19.8198 22.6845 19.1635 23.2167 18.5838 22.9122L12 19.453L5.41616 22.9122C5.3437 22.9502 5.27005 22.9752 5.19685 22.9885C4.68449 23.0813 4.19474 22.5995 4.29685 22.0045L5.54144 14.7526L0.257945 9.60575C0.080497 9.43289 -0.000170449 9.20857 2.70384e-07 8.98765C0.000126456 8.82435 0.044417 8.66291 0.126434 8.52547C0.242222 8.33144 0.433201 8.18526 0.681258 8.14924L8.02897 7.08223ZM11.9977 17.674C12.117 17.6736 12.2363 17.7023 12.3462 17.76L17.8749 20.6647L16.8335 14.5969C16.7844 14.3108 16.8767 14.0179 17.0776 13.8222L21.4379 9.57475L15.3594 8.69206C15.1081 8.65557 14.8887 8.49152 14.7702 8.2515L12 2.64205L11.9977 2.64675V17.674Z" fill="black" />
              </svg></i><span>Reviews</span>
            </Link>
            
          </li>

          <li className={url.includes('brand')?'active':''}>
            <Link className={url.includes('brand')?'open':'close'} onClick={(e)=>toggle(e)}  >
              <i><svg width="24" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 2.125C0 1.08947 0.839466 0.25 1.875 0.25H18.125C19.1605 0.25 20 1.08947 20 2.125V4C20 4.34518 19.7202 4.625 19.375 4.625C18.3395 4.625 17.5 5.46447 17.5 6.5C17.5 7.53553 18.3395 8.375 19.375 8.375C19.7202 8.375 20 8.65482 20 9V10.875C20 11.9105 19.1605 12.75 18.125 12.75H1.875C0.839466 12.75 0 11.9105 0 10.875V9C0 8.65482 0.279822 8.375 0.625 8.375C1.66053 8.375 2.5 7.53553 2.5 6.5C2.5 5.46447 1.66053 4.625 0.625 4.625C0.279822 4.625 0 4.34518 0 4V2.125ZM1.875 1.5C1.52982 1.5 1.25 1.77982 1.25 2.125V3.43751C2.6764 3.72706 3.75 4.98815 3.75 6.5C3.75 8.01185 2.6764 9.27294 1.25 9.56249V10.875C1.25 11.2202 1.52982 11.5 1.875 11.5H18.125C18.4702 11.5 18.75 11.2202 18.75 10.875V9.56249C17.3236 9.27294 16.25 8.01185 16.25 6.5C16.25 4.98815 17.3236 3.72706 18.75 3.43751V2.125C18.75 1.77982 18.4702 1.5 18.125 1.5H1.875Z" fill="black" />
              </svg>
              </i><span>Brand</span>
              <i className='ms-auto'><svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.146447 0.646447C0.341709 0.451184 0.658291 0.451184 0.853553 0.646447L6.85355 6.64645C7.04882 6.84171 7.04882 7.15829 6.85355 7.35355L0.853553 13.3536C0.658291 13.5488 0.341709 13.5488 0.146447 13.3536C-0.0488155 13.1583 -0.0488155 12.8417 0.146447 12.6464L5.79289 7L0.146447 1.35355C-0.0488155 1.15829 -0.0488155 0.841709 0.146447 0.646447Z" fill="#02224E" />
              </svg>
              </i>
            </Link>
            <ul className='ps-4'>
              <li>
              <Link to={"/brand"} >All Brand</Link>
                <Link to={"/add-brand"} >Add Brand</Link>
              </li>

            </ul>


          </li>
          

          <li className={url.includes('faq')?'active':''}>
            <Link className={url.includes('faq')?'open':'close'} onClick={(e)=>toggle(e)}  >
              <i> 
              <svg width="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M123.6 391.3c12.9-9.4 29.6-11.8 44.6-6.4c26.5 9.6 56.2 15.1 87.8 15.1c124.7 0 208-80.5 208-160s-83.3-160-208-160S48 160.5 48 240c0 32 12.4 62.8 35.7 89.2c8.6 9.7 12.8 22.5 11.8 35.5c-1.4 18.1-5.7 34.7-11.3 49.4c17-7.9 31.1-16.7 39.4-22.7zM21.2 431.9c1.8-2.7 3.5-5.4 5.1-8.1c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208s-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6c-15.1 6.6-32.3 12.6-50.1 16.1c-.8 .2-1.6 .3-2.4 .5c-4.4 .8-8.7 1.5-13.2 1.9c-.2 0-.5 .1-.7 .1c-5.1 .5-10.2 .8-15.3 .8c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4c4.1-4.2 7.8-8.7 11.3-13.5c1.7-2.3 3.3-4.6 4.8-6.9c.1-.2 .2-.3 .3-.5z"/></svg>
              </i><span>Faq's</span>
              <i className='ms-auto'><svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.146447 0.646447C0.341709 0.451184 0.658291 0.451184 0.853553 0.646447L6.85355 6.64645C7.04882 6.84171 7.04882 7.15829 6.85355 7.35355L0.853553 13.3536C0.658291 13.5488 0.341709 13.5488 0.146447 13.3536C-0.0488155 13.1583 -0.0488155 12.8417 0.146447 12.6464L5.79289 7L0.146447 1.35355C-0.0488155 1.15829 -0.0488155 0.841709 0.146447 0.646447Z" fill="#02224E" />
              </svg>
              </i>
            </Link>
            <ul className='ps-4'>
              <li>
                <Link to={"/faqs"} >All Faq's</Link>
                <Link to={"/add-faq"} >Add Faq</Link>
              </li>

            </ul>
          </li>

          <li className={url.includes('sign-up-fields')?'active':''}>
            <Link to={"/sign-up-fields"}>
              <i><svg width="24" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.86302 2.81236C5.39068 2.11797 7.55965 1.66667 10 1.66667C12.4404 1.66667 14.6093 2.11797 16.137 2.81236C17.7399 3.54094 18.3333 4.37714 18.3333 5C18.3333 5.62286 17.7399 6.45906 16.137 7.18764C14.6093 7.88203 12.4404 8.33333 10 8.33333C7.55965 8.33333 5.39068 7.88203 3.86302 7.18764C2.26014 6.45906 1.66667 5.62286 1.66667 5C1.66667 4.37714 2.26014 3.54094 3.86302 2.81236ZM18.3333 7.83021V10C18.3333 10.6229 17.7399 11.4591 16.137 12.1876C14.6093 12.882 12.4404 13.3333 10 13.3333C7.55965 13.3333 5.39068 12.882 3.86302 12.1876C2.26014 11.4591 1.66667 10.6229 1.66667 10V7.83021C2.11841 8.16743 2.63317 8.45938 3.17335 8.70492C4.96337 9.51856 7.37774 10 10 10C12.6223 10 15.0366 9.51856 16.8266 8.70492C17.3668 8.45938 17.8816 8.16743 18.3333 7.83021ZM20 5C20 3.32167 18.5414 2.07454 16.8266 1.29508C15.0366 0.481437 12.6223 0 10 0C7.37774 0 4.96337 0.481437 3.17335 1.29508C1.45856 2.07454 0 3.32167 0 5V20C0 21.6783 1.45856 22.9255 3.17335 23.7049C4.96337 24.5186 7.37774 25 10 25C12.6223 25 15.0366 24.5186 16.8266 23.7049C18.5414 22.9255 20 21.6783 20 20V5ZM18.3333 12.8302V15C18.3333 15.6229 17.7399 16.4591 16.137 17.1876C14.6093 17.882 12.4404 18.3333 10 18.3333C7.55965 18.3333 5.39068 17.882 3.86302 17.1876C2.26014 16.4591 1.66667 15.6229 1.66667 15V12.8302C2.11841 13.1674 2.63317 13.4594 3.17335 13.7049C4.96337 14.5186 7.37774 15 10 15C12.6223 15 15.0366 14.5186 16.8266 13.7049C17.3668 13.4594 17.8816 13.1674 18.3333 12.8302ZM18.3333 17.8302V20C18.3333 20.6229 17.7399 21.4591 16.137 22.1876C14.6093 22.882 12.4404 23.3333 10 23.3333C7.55965 23.3333 5.39068 22.882 3.86302 22.1876C2.26014 21.4591 1.66667 20.6229 1.66667 20V17.8302C2.11841 18.1674 2.63317 18.4594 3.17335 18.7049C4.96337 19.5186 7.37774 20 10 20C12.6223 20 15.0366 19.5186 16.8266 18.7049C17.3668 18.4594 17.8816 18.1674 18.3333 17.8302Z" fill="black" />
              </svg>
              </i><span>User fields</span>
            </Link>
          </li>

          <li className={url.includes('social-media-analytics')?'active':''}>
            <Link to={"/social-media-analytics"}>
              <i><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H1.5V22.5H24V24H0V0ZM15 5.25C15 4.83579 15.3358 4.5 15.75 4.5H21.75C22.1642 4.5 22.5 4.83579 22.5 5.25V11.25C22.5 11.6642 22.1642 12 21.75 12C21.3358 12 21 11.6642 21 11.25V7.35106L15.5805 13.9749C15.4463 14.1389 15.249 14.2385 15.0374 14.2491C14.8257 14.2596 14.6195 14.1802 14.4697 14.0303L10.5903 10.151L5.10655 17.6911C4.86292 18.0261 4.39386 18.1002 4.05887 17.8566C3.72388 17.6129 3.64982 17.1439 3.89345 16.8089L9.89345 8.55887C10.0225 8.38148 10.2227 8.26945 10.4413 8.2523C10.66 8.23514 10.8752 8.31457 11.0303 8.46967L14.9443 12.3837L20.1673 6H15.75C15.3358 6 15 5.66421 15 5.25Z" fill="black" />
              </svg>
              </i><span>Social Media Analytics</span>
            </Link>
          </li>

          <li className={url.includes('banner')?'active':''}>
            <Link className={url.includes('banner')?'open':'close'} onClick={(e)=>toggle(e)}>
              <i><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 0C7.91421 0 8.25 0.335786 8.25 0.75V3H9.75V0.75C9.75 0.335786 10.0858 0 10.5 0C10.9142 0 11.25 0.335786 11.25 0.75V3H12.75V0.75C12.75 0.335786 13.0858 0 13.5 0C13.9142 0 14.25 0.335786 14.25 0.75V3H15.75V0.75C15.75 0.335786 16.0858 0 16.5 0C16.9142 0 17.25 0.335786 17.25 0.75V3C19.3211 3 21 4.67893 21 6.75H23.25C23.6642 6.75 24 7.08579 24 7.5C24 7.91421 23.6642 8.25 23.25 8.25H21V9.75H23.25C23.6642 9.75 24 10.0858 24 10.5C24 10.9142 23.6642 11.25 23.25 11.25H21V12.75H23.25C23.6642 12.75 24 13.0858 24 13.5C24 13.9142 23.6642 14.25 23.25 14.25H21V15.75H23.25C23.6642 15.75 24 16.0858 24 16.5C24 16.9142 23.6642 17.25 23.25 17.25H21C21 19.3211 19.3211 21 17.25 21V23.25C17.25 23.6642 16.9142 24 16.5 24C16.0858 24 15.75 23.6642 15.75 23.25V21H14.25V23.25C14.25 23.6642 13.9142 24 13.5 24C13.0858 24 12.75 23.6642 12.75 23.25V21H11.25V23.25C11.25 23.6642 10.9142 24 10.5 24C10.0858 24 9.75 23.6642 9.75 23.25V21H8.25V23.25C8.25 23.6642 7.91421 24 7.5 24C7.08579 24 6.75 23.6642 6.75 23.25V21C4.67893 21 3 19.3211 3 17.25H0.75C0.335786 17.25 0 16.9142 0 16.5C0 16.0858 0.335786 15.75 0.75 15.75H3V14.25H0.75C0.335786 14.25 0 13.9142 0 13.5C0 13.0858 0.335786 12.75 0.75 12.75H3V11.25H0.75C0.335786 11.25 0 10.9142 0 10.5C0 10.0858 0.335786 9.75 0.75 9.75H3V8.25H0.75C0.335786 8.25 0 7.91421 0 7.5C0 7.08579 0.335786 6.75 0.75 6.75H3C3 4.67893 4.67893 3 6.75 3V0.75C6.75 0.335786 7.08579 0 7.5 0ZM6.75 4.5C5.50736 4.5 4.5 5.50736 4.5 6.75V17.25C4.5 18.4926 5.50736 19.5 6.75 19.5H17.25C18.4926 19.5 19.5 18.4926 19.5 17.25V6.75C19.5 5.50736 18.4926 4.5 17.25 4.5H6.75ZM7.5 9.75C7.5 8.50736 8.50736 7.5 9.75 7.5H14.25C15.4926 7.5 16.5 8.50736 16.5 9.75V14.25C16.5 15.4926 15.4926 16.5 14.25 16.5H9.75C8.50736 16.5 7.5 15.4926 7.5 14.25V9.75ZM9.75 9C9.33579 9 9 9.33579 9 9.75V14.25C9 14.6642 9.33579 15 9.75 15H14.25C14.6642 15 15 14.6642 15 14.25V9.75C15 9.33579 14.6642 9 14.25 9H9.75Z" fill="black" />
              </svg>
              </i><span>Banners</span>
              <i className='ms-auto'><svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.146447 0.646447C0.341709 0.451184 0.658291 0.451184 0.853553 0.646447L6.85355 6.64645C7.04882 6.84171 7.04882 7.15829 6.85355 7.35355L0.853553 13.3536C0.658291 13.5488 0.341709 13.5488 0.146447 13.3536C-0.0488155 13.1583 -0.0488155 12.8417 0.146447 12.6464L5.79289 7L0.146447 1.35355C-0.0488155 1.15829 -0.0488155 0.841709 0.146447 0.646447Z" fill="#02224E" />
              </svg>
              </i>
            </Link>
            
            <ul className='ps-4'>
              <li>
                <Link to={"/banner/1"} >Main Banners</Link>
                <Link to={"/banner/2"} >Side Banners</Link>
                <Link to={"/banner/3"} >Middle Banners</Link>
                <Link to={"/banner/4"} >Bottom Banners</Link>
              </li>

            </ul>
          </li>
          <li className={url.includes('newsletters')?'active':''}>
            <Link to={"/newsletters"}>
              <i><svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 3C0 1.34315 1.27319 0 2.84375 0H19.9062C21.4768 0 22.75 1.34315 22.75 3V15C22.75 16.6569 21.4768 18 19.9062 18H2.84375C1.27319 18 0 16.6569 0 15V3ZM2.84375 1.5C2.05847 1.5 1.42188 2.17157 1.42188 3V3.32536L11.375 9.62536L21.3281 3.32536V3C21.3281 2.17157 20.6915 1.5 19.9062 1.5H2.84375ZM21.3281 5.07464L14.6338 9.31191L21.3281 13.6578V5.07464ZM21.2801 15.3879L13.2602 10.1814L11.375 11.3746L9.48984 10.1814L1.46988 15.3879C1.63195 16.0283 2.18564 16.5 2.84375 16.5H19.9062C20.5644 16.5 21.1181 16.0283 21.2801 15.3879ZM1.42188 13.6578L8.11617 9.31191L1.42188 5.07464V13.6578Z" fill="#161616"/>
</svg>

              </i><span>Newsletters</span>
            </Link>
          </li>
          <li className={url.includes('social-media-links')?'active':''}>
            <Link to={"/social-media-links"}>
              <i><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.0156 5.19251C18.0156 5.54338 18.3678 5.89425 18.8602 5.89425C19.5636 5.89425 19.7748 5.61355 19.7748 5.19251C19.7748 5.12234 19.7748 5.12234 19.7748 5.05248C19.7047 4.84196 19.4933 4.70161 19.2828 4.56126C19.2121 4.56126 19.0716 4.49109 18.9312 4.49109C18.4379 4.4914 18.0156 4.77178 18.0156 5.19251Z" fill="black"/>
<path d="M19.495 2.87689C19.424 2.45585 19.1428 2.10498 18.7912 2.10498C18.439 2.10498 18.2278 2.45585 18.2989 2.87689C18.3699 3.29793 18.6504 3.6488 19.0023 3.6488C19.2838 3.6488 19.565 3.29793 19.495 2.87689Z" fill="black"/>
<path d="M16.329 8.13983L17.5254 6.94687C18.2289 7.50826 19.0744 7.85913 19.989 7.85913C22.1704 7.85913 23.9302 6.10448 23.9302 3.92941C23.9295 1.75403 22.1704 0 19.9886 0C17.8075 0 16.0475 1.75434 16.0475 3.92972C16.0475 4.91215 16.399 5.75392 16.9624 6.3858L15.7656 7.57906C15.9768 7.78927 16.1879 8.0001 16.329 8.13983ZM20.6221 2.66628H21.3959V1.89469H21.6774V2.6666H22.4519V2.94729H21.6774V3.71951H21.3959V2.94698H20.6221V2.66628ZM18.8626 3.99958C18.8626 3.92941 18.8626 3.85924 18.9336 3.78906C18.8626 3.78906 18.8626 3.78906 18.7932 3.78906C18.2298 3.78906 17.8075 3.36802 17.8075 2.8768C17.8075 2.38559 18.3703 1.96455 18.9336 1.96455H20.1998L19.9183 2.17507H19.496C19.7775 2.24556 19.9183 2.59642 19.9183 2.94698C19.9183 3.22767 19.7775 3.43819 19.5661 3.64871C19.3549 3.78906 19.2845 3.85924 19.2845 3.99958C19.2845 4.13993 19.496 4.35045 19.6364 4.42062C19.988 4.63115 20.0587 4.91184 20.0587 5.26271C20.0587 5.75361 19.6364 6.17496 18.7922 6.17496C18.0887 6.17496 17.455 5.89427 17.455 5.40306C17.455 4.91184 18.0177 4.4908 18.7215 4.4908C18.7922 4.4908 18.862 4.4908 18.9327 4.4908C18.9327 4.28059 18.8626 4.14024 18.8626 3.99958Z" fill="black"/>
<path d="M19.9883 16.1399C19.0737 16.1399 18.1587 16.4904 17.5247 17.0522L16.3286 15.8589C16.1875 16.0694 15.9764 16.2091 15.8359 16.3495L17.0327 17.5427C16.4697 18.2438 16.1178 19.0869 16.1178 19.9991C16.1178 22.1742 17.8779 23.9289 20.0593 23.9289C22.2404 23.9289 24.0008 22.1742 24.0008 19.9991C23.9291 17.8237 22.17 16.1399 19.9883 16.1399ZM18.8622 21.824H18.088V19.3676H18.8622V21.824ZM18.5103 19.0173C18.2288 19.0173 18.018 18.8065 18.018 18.5957C18.018 18.3851 18.2291 18.1746 18.5103 18.1746C18.7915 18.1746 19.0027 18.3851 19.0027 18.5957C18.9323 18.8767 18.7918 19.0173 18.5103 19.0173ZM22.0292 21.824H21.1143V20.5611C21.1143 20.2106 20.9739 19.9998 20.6917 19.9998C20.4806 19.9998 20.3402 20.1401 20.2695 20.2805C20.2695 20.3516 20.2695 20.4211 20.2695 20.4913V21.8249H19.4249C19.4249 21.8249 19.4249 19.5793 19.4249 19.3685H20.2695V19.7194C20.3402 19.5793 20.6217 19.2983 21.0439 19.2983C21.6067 19.2983 22.0292 19.6489 22.0292 20.4211V21.824Z" fill="black"/>
<path d="M7.60087 15.8596L6.40444 17.0529C5.70066 16.4912 4.85611 16.1406 3.94119 16.1406C1.75946 16.1406 0 17.8956 0 20.07C0 22.2454 1.75946 24.0001 3.94119 24.0001C6.12261 24.0001 7.88239 22.2454 7.88239 20.07C7.88239 19.1578 7.5305 18.2458 6.96747 17.614L8.1639 16.4207C7.95277 16.2099 7.74163 15.999 7.60087 15.8596ZM5.63059 19.4382C5.63059 20.6311 4.71567 21.9641 3.09697 21.9641C2.60463 21.9641 2.11167 21.8237 1.75978 21.5424C1.83015 21.5424 1.90053 21.5424 1.97091 21.5424C2.39318 21.5424 2.74507 21.4024 3.09697 21.1919C2.74507 21.1919 2.39318 20.9112 2.25243 20.56C2.3228 20.56 2.39287 20.56 2.39287 20.56C2.46356 20.56 2.53362 20.56 2.60432 20.56C2.18173 20.4892 1.90053 20.139 1.90053 19.7179C2.04129 19.7884 2.18205 19.8579 2.3228 19.8579C2.11167 19.7179 1.90053 19.4369 1.90053 19.1562C1.90053 19.0165 1.97091 18.8755 2.04129 18.7352C2.46356 19.2969 3.09665 19.6474 3.87082 19.6474C3.87082 19.5766 3.87082 19.5074 3.87082 19.4369C3.87082 18.9457 4.2934 18.5246 4.78574 18.5246C5.06725 18.5246 5.27839 18.6647 5.41946 18.8053C5.63059 18.7349 5.84173 18.665 5.98248 18.5948C5.91179 18.8053 5.77103 18.9454 5.5599 19.0854C5.77103 19.0854 5.91179 19.0149 6.05255 18.9454C5.91179 19.1559 5.77103 19.2959 5.63028 19.3664C5.63059 19.3677 5.63059 19.3677 5.63059 19.4382Z" fill="black"/>
<path d="M3.94119 7.85951C4.85611 7.85951 5.77072 7.50865 6.40444 6.94726L7.60087 8.14021C7.74163 7.92969 7.95277 7.78965 8.09352 7.64931L6.89678 6.45635C7.4598 5.75462 7.8117 4.91254 7.8117 4.00028C7.8117 1.8249 6.05223 0.0705566 3.8705 0.0705566C1.68908 0.0705566 0 1.8249 0 4.00028C0 6.10486 1.75946 7.85951 3.94119 7.85951ZM3.02627 3.29792H3.44854V2.87687C3.44854 2.31549 3.65968 1.96462 4.36346 1.96462H4.92649V2.66635H4.5746C4.29309 2.66635 4.29309 2.73653 4.29309 2.94705V3.29792H4.92649L4.85611 3.99965H4.22208V5.96451H3.37754V3.99965H2.95558V3.29792H3.02627Z" fill="black"/>
<path d="M15.5545 13.9642C15.4835 13.8245 15.343 13.7534 15.273 13.7534C14.6396 13.5429 14.0769 13.4032 13.4435 13.1923C13.3731 13.1923 13.2323 13.0523 13.2323 12.7713C13.2323 12.6306 13.2323 12.5605 13.0919 12.5605C13.0209 12.5605 13.0919 12.5605 13.0209 12.4903C12.9508 12.2096 12.9508 12.0689 12.9508 11.9991C12.9508 11.9292 13.0209 11.9286 13.0209 11.8587C13.2323 11.578 13.3024 11.1573 13.3024 11.0166C13.3024 11.0166 13.3731 11.0166 13.3731 10.9462C13.4435 10.8058 13.4435 10.8058 13.4435 10.595C13.5145 10.4553 13.5145 10.2447 13.3731 10.2447C13.3024 10.3149 13.3024 10.2447 13.3024 10.1044V9.2623C13.3024 8.98161 13.0909 8.7714 12.9508 8.70123C12.739 8.56057 12.6693 8.49039 12.5986 8.49039C12.5279 8.42022 12.5279 8.35005 12.5986 8.27987C12.669 8.21001 12.739 8.21001 12.739 8.13953C12.739 8.13953 12.739 8.13953 12.6684 8.13953C12.5977 8.13953 12.176 8.21033 11.9649 8.27987C11.613 8.35005 11.2611 8.49039 10.9793 8.70123C10.7681 8.84126 10.6274 9.05178 10.6274 9.3331C10.6274 9.47282 10.6274 9.89418 10.6274 10.1752C10.6274 10.246 10.6274 10.3155 10.557 10.246C10.3459 10.246 10.4866 10.5267 10.4866 10.5962C10.4866 10.7366 10.557 10.8071 10.6271 10.9474C10.6271 11.0179 10.6978 11.0179 10.6978 11.0179C10.7681 11.2284 10.8385 11.6495 10.9793 11.86C10.9793 11.86 11.05 11.9305 11.05 12.0003C11.05 12.1407 11.05 12.3512 10.9793 12.4915C10.9793 12.4915 10.9793 12.562 10.9089 12.562C10.7681 12.562 10.7681 12.6322 10.7681 12.7726C10.7681 12.9831 10.6274 13.1939 10.557 13.1939C10.2051 13.3336 9.14974 13.6848 8.79753 13.755C8.5864 13.8258 8.51602 13.8953 8.44564 13.9655L8.09375 14.8076C9.50101 14.8076 10.2051 15.6497 11.6831 15.9304H12.3868C13.8648 15.7198 14.9201 14.8076 15.9058 14.8076L15.5545 13.9642Z" fill="black"/>
</svg>

              </i><span>Social Media Links</span>
            </Link>
          </li>

          
      


        </ul>
      </nav>
    </div>

  )
}

export default Sidebar;