import React from "react";
import Footer from "../common/Footer";
import Header from "../common/Header";
import Sidebar from "../common/sidebar";
import CategoryList from "../section/home/categories/sub-categories-list";
const Categories = () => {
  React.useEffect(() => {
    document.title = "Sub Categories";
  }, []);

  return (
    <div className="bg-grey h-100">
      <Header />

      <section className="content-area mt-4">
        <Sidebar />
        <div className="Right-content">
          <div className="main-content">
            <CategoryList />
          </div>
          <Footer />
        </div>
      </section>


    </div>
  );
};

export default Categories;