import axios from "axios";
import AuthService from "../services/auth.service";

const API_URL = (process.env.NODE_ENV != 'production' ? "https://api.dealitt.com/" : "https://api.dealitt.com/");

axios.interceptors.request.use(function (config) {
  const token = AuthService.getCurrentUserTokken();
  config.headers.Authorization = 'Bearer ' + token;

  return config;
});
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response.status === 401) {
    localStorage.removeItem("user");
    window.location.href = '/#/login'
    // Hace la solicitud de refresco de tokens
  }
  return Promise.reject(error);
});
const addCategory = (data) => {
  return axios.post(API_URL + "api/categories", data);
};
const getCategory = (type) => {
  return axios.get(API_URL + "api/categories?type=" + type);
};
const getCategoryDetail = (id) => {
  return axios.get(API_URL + "api/categories/" + id);
};

const updateCategory = (data, id) => {
  return axios.put(API_URL + "api/categories/" + id, data);
};
const updateCategoryOrder = (data) => {
  return axios.put(API_URL + "api/categories/order", data);
};
const getAllCategory = (type) => {
  return axios.get(API_URL + "api/categories");
};
const addProduct = (data) => {
  return axios.post(API_URL + "api/products", data);
};
const getProduct = (page = 1, limit = 15, search = '', status = '') => {
  return axios.get(API_URL + "api/products?page=" + page + "&limit=" + limit + "&search=" + search + "&status=" + status);
};
const updateProduct = (data, id) => {
  return axios.put(API_URL + "api/products/" + id, data);
};
const deleteProductImage = (id, key) => {
  return axios.delete(API_URL + "api/products/" + id + "/image/" + key);
};
const deleteProductDocument = (id, key) => {
  return axios.delete(API_URL + "api/products/" + id + "/document/" + key);
};
const getProductDetail = (id) => {
  return axios.get(API_URL + "api/products/" + id);
};
const addBrand = (data) => {
  return axios.post(API_URL + "api/brands", data);
};
const getAllBrand = (type) => {
  return axios.get(API_URL + "api/brands");
};
const getBrandDetail = (id) => {
  return axios.get(API_URL + "api/brands/" + id);
};
const deleteBrand = (id) => {
  return axios.delete(API_URL + "api/brands/" + id);
};
const updateBrand = (data, id) => {
  return axios.put(API_URL + "api/brands/" + id, data);
};
const addBlog = (data) => {
  return axios.post(API_URL + "api/blog", data);
};
const getBlog = (data) => {
  return axios.get(API_URL + "api/blog");
};
const addPage = (data) => {
  return axios.post(API_URL + "api/pages", data);
};
const getPage = (type) => {
  return axios.get(API_URL + "api/pages?page_type=" + type);
};
const updatePage = (pageId, data) => {
  return axios.put(API_URL + "api/pages/" + pageId, data);
};

const addCustomer = (data) => {
  return axios.post(API_URL + "api/users/addcustomer", data);
};
const addVendor = (id, data) => {
  return axios.post(API_URL + "api/store/" + id, data);
};
const getUser = (type) => {
  return axios.get(API_URL + "api/users?role=" + type);
};



const addBanner = (data) => {
  return axios.post(API_URL + "api/banners", data);
};
const getBanner = (type) => {
  return axios.get(API_URL + "api/banners?type=" + type);
};
const deleteBanner = (id) => {
  return axios.delete(API_URL + "api/banners/" + id);
};
const updateBannerOrder = (data) => {
  return axios.put(API_URL + "api/banners/order", data);
};
const getBannerDetail = (id) => {
  return axios.get(API_URL + "api/banners/" + id);
};
const updateBanner = (id, data) => {
  return axios.put(API_URL + "api/banners/" + id, data);
};
const getUserDetail = () => {
  return axios.get(API_URL + "api/users/detail");
};
const updateUser = (data, id) => {
  return axios.put(API_URL + "api/users/" + id, data);
};
const getCustomerDetail = (id) => {
  return axios.get(API_URL + "api/vendor/userdetail/" + id);
};
const updateCustomerDetail = (id, data) => {
  return axios.put(API_URL + "api/vendor/userdetail/" + id, data);
};
const getOrder = () => {
  return axios.get(API_URL + "api/order");
};
const getOrderDetail = (id) => {
  return axios.get(API_URL + "api/order/" + id);
};
const updateOrder = (id, data) => {
  return axios.put(API_URL + "api/order/" + id, data);
};
const getUserField = (id) => {
  return axios.get(API_URL + "api/userfield");
};
const addUserField = (data, type) => {
  return axios.post(API_URL + "api/userfield?type=" + type, data);
};
const addFaq = (data) => {
  return axios.post(API_URL + "api/faqs", data);
};
const getAllFaq = (type) => {
  return axios.get(API_URL + "api/faqs");
};
const getFaqDetail = (id) => {
  return axios.get(API_URL + "api/faqs/" + id);
};
const updateFaq = (data, id) => {
  return axios.put(API_URL + "api/faqs/" + id, data);
};
const changePassword = (data) => {
  return axios.post(API_URL + "api/users/changepassword", data,);
};
const getDashboard = () => {
  return axios.get(API_URL + "api/dashboard");
};
const getBlogDetails = (id) => {
  return axios.get(API_URL + "api/blog/" + id);
};
const updateBlog = (data, id) => {
  return axios.put(API_URL + "api/blog/" + id, data);
};
const deleteBlog = (id) => {
  return axios.delete(API_URL + "api/blog/" + id);
};
const getSocialLinks = () => {
  return axios.get(API_URL + "api/front/social/");
};
const addSocialLinks = (data) => {
  return axios.post(API_URL + "api/front/social", data);
};
const getNewsletter = () => {
  return axios.get(API_URL + "api/newsletter");
};
const deleteNewsletter = (id) => {
  return axios.delete(API_URL + "api/newsletter/" + id);
};
const cancelOrder = (data, id) => {
  return axios.post(API_URL + "api/order/" + id + "/cancel", data);
};
const forgotPassword = (data) => {
  return axios.post(API_URL + "api/users/forgotpassword", data);
};
const verifyUserOtp = (data) => {
  return axios.post(API_URL + "api/users/verifyotp", data);
};
const resetPassword = (data) => {
  return axios.post(API_URL + "api/users/resetpassword", data,);
};
const getContactUs = (data) => {
  return axios.get(API_URL + "api/front/contact-page", data);
};
const addContactUs = (data) => {
  return axios.post(API_URL + "api/front/contact-page", data);
};
const getAllReviews = () => {
  return axios.get(API_URL + "api/review");
};
const getReviewDetail = (id) => {
  return axios.get(API_URL + "api/review/" + id);
};
const updateReviewDetail = (id, data) => {
  return axios.put(API_URL + "api/review/" + id, data);
};
const getProductReview = (id) => {
  return axios.get(API_URL + "api/products/" + id + "/reviews");
};
const getNotifications = (read = false) => {
  return axios.get(API_URL + "api/users/notifications" + (read == true ? "?read=yes" : ""));
};


const getVendor = (data) => {
  return axios.get(API_URL + "api/vendor/store", data);
};
const getCompanyProfile = (slug) => {
  return axios.get(API_URL + `api/store/${slug}`);
};
const getAllStore = (data) => {
  return axios.get(API_URL + "api/store", data);
};

const editCompanyProfile = (id, data) => {
  return axios.post(API_URL + "api/store/" + id, data);
};
const getWorld = (entity) => {
  return axios.get(API_URL + "api/front/" + entity);
};
const getContinents = () => {
  return axios.get(API_URL + "api/front/continents");
};
const DataService = {
  getCompanyProfile,
  getAllStore,
  addCategory,
  getCategory,
  getAllCategory,
  addProduct,
  getProduct,
  addBrand,
  getAllBrand,
  addBlog,
  getBlog,
  addPage,
  getPage,
  updatePage,
  addCustomer,
  getUser,
  addVendor,
  addBanner,
  getBanner,
  deleteBanner,
  getProductDetail,
  updateProduct,
  getUserDetail,
  updateUser,
  getCategoryDetail,
  updateCategory,
  getBrandDetail,
  updateBrand,
  getCustomerDetail,
  updateCustomerDetail,
  getBannerDetail,
  updateBanner,
  getOrder,
  getOrderDetail,
  getUserField,
  addUserField,
  addFaq,
  getAllFaq,
  getFaqDetail,
  updateFaq,
  updateCategoryOrder,
  updateBannerOrder,
  deleteBrand,
  deleteProductImage,
  deleteProductDocument,
  changePassword,
  getDashboard,
  getBlogDetails,
  updateBlog,
  deleteBlog,
  addSocialLinks,
  getSocialLinks,
  getNewsletter,
  deleteNewsletter,
  cancelOrder,
  forgotPassword,
  verifyUserOtp,
  resetPassword,
  getContactUs,
  addContactUs,
  getAllReviews,
  getReviewDetail,
  getProductReview,
  updateReviewDetail,
  updateOrder,
  getNotifications,
  getCompanyProfile,
  getVendor,
  editCompanyProfile,
  getWorld,
  getContinents
}
export default DataService;