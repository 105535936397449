import React from 'react'
import Header from '../common/Header'
import Footer from '../common/Footer'
import Sidebar from '../common/sidebar'
import CompanyList from '../section/home/Company/company-list'


const AllStore = () => {
  return (
    <>
     <div className="bg-grey h-100">
      <Header />
      
<section className="content-area mt-4">
    <Sidebar/>
    <div className="Right-content">
      <div className="main-content">
        <CompanyList/>
      </div> 
      <Footer />
    </div>
</section>
      
     
    </div>
    
    </>
  )
}

export default AllStore