import React, { Fragment } from "react";

const SocialMediaAnalyticsBanner = () => {
    return (
        <div className="MyprofileBanner d-flex justify-content-center align-items-center">
          <h3 className="text-white">Social Media Channel Analytics</h3>
        </div>
    );
};

export default SocialMediaAnalyticsBanner;